<template>
    <v-container>
        <v-row align="top">
            <v-col lg="4">
                <v-card class="mt-12" flat width="400" max-height="700">
                    <v-card-text>
                        <v-alert outlined dismissible class="mt-5" type="error" v-if="error"> Λάθος συνδιασμός όνομα
                            χρήστη και κωδικού
                        </v-alert>
                        <v-form>
                            <v-text-field label="Όνομα Χρήστη" v-model="username" type="text"/>
                            <v-text-field label="Κωδικός" v-model="password" type="password" hide-details/>
                            <v-checkbox v-model="rememberMe" :value="true" label="Να παραμείνω σε σύνδεση"/>
                            <v-btn color="primary" @click="login"> Σύνδεση</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import {mapActions} from 'vuex';

    export default {
        name: "Login",
        data: () => ({
            error: false,
            username: "",
            password: "",
            rememberMe: false
        }),
        methods: {
            ...mapActions([
                "getSubscriptionDetails"
            ]),
            login() {
                this.error = false;
                this.getSubscriptionDetails(
                    {
                        username: this.username,
                        password: this.password,
                        rememberMe: this.rememberMe
                    }).then(
                    (user) => {
                        if (!this.$route.query.from) this.$router.push('/');
                        this.$router.replace(this.$route.query.from);
                    },
                ).catch(err => {
                    this.error = true;
                })
            }
        }
    }
</script>

<style scoped>

</style>
